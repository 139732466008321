import { Language } from '@summer-health/types/locale';

export enum UserStatus {
  New = 'new',
  Registered = 'registered',
  /**
   * User identity exists in our db and identity provider (auth0)
   * Used to keep an identity around but block it from usage
   */
  Blocked = 'blocked',
  /**
   * User identity has been removed from identity provider and
   * just exists for record keeping
   */
  Removed = 'removed',
}

// User
export interface User {
  userId: string;
  status: UserStatus;
  auth0Id: string;
  phoneNumber: string;
  createDate: Date;
  registeredDate?: Date;
  blockedDate?: Date;
  removedDate?: Date;
  email?: string;
  language?: Language;
}

export enum UserRegistrationSource {
  Sponsored = 'sponsored',
  Checkout = 'checkout',
  Partnership = 'partnership',
  Medicaid = 'medicaid',
}

export enum UserIdentityType {
  SMS = 'sms',
  Email = 'email',
}

export interface UserIdentity {
  type: UserIdentityType;
  value: string;
  verified: boolean;
}

export interface Auth0User {
  userId: string;
  email?: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
}
