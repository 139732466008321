import { UserProvider } from '@auth0/nextjs-auth0/client';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { getLogger } from '@summer-health/shared/next-logger';
import { theme } from '@summer-health/ui/care-theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { appWithTranslation } from 'next-i18next';
import createEmotionCache from '../styles/createEmotionCache';
import { AppConfig } from '../types/app-config';
import { Flags } from '../types/flags';
import { asyncConfig } from '../utils/asyncConfigSetup';
import { MobileAppProvider } from '../components/context/mobile-app-provider';
import { ThirdPartyAnalytics } from '../components/shared/third-party-analytics';
import { PHProvider } from '../components/providers/posthog-provider';

dayjs.extend(utc);
dayjs.extend(timezone);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  emotionCache?: EmotionCache;
};

const logger = getLogger('CareApp');

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function CareApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout): React.ReactNode {
  const [flags, setFlags] = useState({} as Flags);
  const [config, setConfig] = useState({} as AppConfig);
  const getLayout =
    Component.getLayout ??
    ((page: JSX.Element, _: object): JSX.Element => page);

  const router = useRouter();
  const isKioskRoute = router.pathname.startsWith('/kiosk');

  const updateConfig = async (): Promise<void> => {
    if (isKioskRoute) {
      return;
    }
    logger.debug('Updating user config');
    asyncConfig(router, setFlags, setConfig);
  };

  useEffect(() => {
    const handleRouteChangeError = (err, url): void => {
      logger.warn({ err, debug: { url } }, `Route change error for ${url}`);
    };

    router.events.on('routeChangeError', handleRouteChangeError);

    updateConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = (): ReactNode => {
    const pageContent = getLayout(
      <Component
        {...pageProps}
        flags={{ ...(pageProps.flags ?? {}), ...flags }}
        config={config}
      />,
      {
        ...pageProps,
        flags: {
          ...(pageProps.flags ?? {}),
          ...flags,
        },
        config,
        updateConfig,
      },
    );

    if (isKioskRoute) {
      return pageContent;
    }

    return (
      <CssVarsProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline enableColorScheme />
        <main>
          <Toaster />
          <UserProvider>
            <PHProvider config={config}>
              <MobileAppProvider>
                <ThirdPartyAnalytics config={config} />
                {pageContent}
              </MobileAppProvider>
            </PHProvider>
          </UserProvider>
        </main>
      </CssVarsProvider>
    );
  };

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Summer Health</title>
        <meta
          name="viewport"
          // eslint-disable-next-line @stylistic/max-len
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        {renderContent()}
      </QueryClientProvider>
    </CacheProvider>
  );
}

export default appWithTranslation(CareApp);
