import { useUser } from '@auth0/nextjs-auth0/client';
import { UserStatus } from '@summer-health/types/user';
import { useRouter } from 'next/router';
import { ReactElement, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMobileAppDetection } from './use-mobile-app-detection';

interface MobileAppProviderProps {
  children: React.ReactNode;
}

interface MobileAppContext {
  isMobileApp: boolean;
}
export const MobileAppContext = createContext<MobileAppContext>({
  isMobileApp: false,
});

export function MobileAppProvider({
  children,
}: MobileAppProviderProps): ReactElement<MobileAppContext> {
  const { user } = useUser();
  const router = useRouter();
  const { i18n } = useTranslation();
  const langPrefix = i18n.language === 'en' ? '' : `/${i18n.language}`;

  const { isMobileApp } = useMobileAppDetection();

  useEffect(() => {
    if (isMobileApp && user?.status === UserStatus.New) {
      router.push(`/api/auth/logout?returnTo=${langPrefix}/app/unregistered`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isMobileApp]);

  return (
    <MobileAppContext.Provider
      value={{
        isMobileApp,
      }}
    >
      {children}
    </MobileAppContext.Provider>
  );
}
