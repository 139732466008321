import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { AppConfig } from '../../types/app-config';

export function PHProvider({
  children,
  config,
}: {
  children: React.ReactNode;
  config: AppConfig;
}): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    if (!config.posthogApiKey || !config.posthogHost) return;
    // Initialize PostHog
    posthog.init(config.posthogApiKey, {
      api_host: config.posthogHost,
      capture_pageview: false, // Disable automatic capture as we'll do it manually
    });

    // eslint-disable-next-line consistent-return
    return (): void => {
      posthog.reset();
    };
  }, [config.posthogApiKey, config.posthogHost]);

  useEffect(() => {
    // Handle initial page load
    const url = window.location.href;
    posthog.capture('$pageview', {
      $current_url: url,
    });

    // Handle subsequent route changes
    const handleRouteChange = (): void => {
      const url = window.location.href;
      posthog.capture('$pageview', {
        $current_url: url,
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
